<template>
  <div>
    <div class="headerbox">
    </div>
    <div class="center">
      <div class="title" id="titlechange">{{newdetiledata.title}}</div>
      <div class="content" v-html="newdetiledata.content"></div>
      <div class="timebox">{{ $formatTime(newdetiledata.createtime * 1000, "dd-MM-yyyy") }}</div>
      <div class="nextbox">
        <div class="lbox point disabled" v-show="lastdata.title==null">Previous news article:No news</div>
        <div class="lbox point" @click="getnew(lastdata.id)" v-show="lastdata.title!=null">Previous news article:{{lastdata.title}}</div>
        <div class="rbox point disabled" v-show="nextdata.title==null">Next news article:No news</div>
        <div class="rbox point" @click="getnew(nextdata.id)" v-show="nextdata.title!=null">Next news article:{{nextdata.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getnews_detailApi } from '../../network/api';
export default {
  data() {
    return {
      newid: null,
      newdetiledata: {},
      lastdata: {},
      nextdata: {}
    };
  },
  created() {
    this.newid = this.$route.query.id;
    this.getdata();
  },
  methods: {
    getnew(v) {
      this.newid = v;
      this.getdata();
      this.backTop();
    },
    backTop() {
      const scrollTop = document.getElementById("titlechange");
      scrollTop.scrollIntoView();
    },
    async getdata() {
      let params = {
        id: this.newid
      };
      const res = await getnews_detailApi(params);
      this.newdetiledata = res.data;
      this.lastdata = res.data.last;
      this.nextdata = res.data.up;
    }
  }
}
</script>

<style lang="scss" scoped>
.headerbox {
  width: 19.2rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/all/pc-new-title2.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.6rem;
  .centertext {
    width: 6.97rem;
    height: 1.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    font-family: Paytone One, Paytone One;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    .line {
      width: 3rem;
      height: 0.02rem;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
    .line2 {
      width: 3rem;
      height: 0.02rem;
      background: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.center {
  width: 13rem;
  margin: 0 auto;
  .title {
    width: 100%;
    height: 1.4rem;
    border-bottom: 0.04rem solid #eef5ee;
    margin-bottom: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.28rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 0.33rem;
  }
  .content {
    font-size: 0.16rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 0.23rem;
  }
  .timebox {
    width: 100%;
    height: 1rem;
    font-size: 0.24rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #898989;
    line-height: 0.28rem;
    border-bottom: 0.04rem solid #eef5ee;
    margin-top: 0.3rem;
    margin-bottom: 0.6rem;
  }
  .nextbox {
    display: flex;
    justify-content: space-between;
    font-size: 0.2rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #767676;
    line-height: 0.23rem;
    margin-bottom: 1.2rem;
  }
  .disabled {
    opacity: 0.5; /* 降低不透明度 */
    cursor: not-allowed; /* 更改光标形状为“not allowed” */
  }
}
</style>